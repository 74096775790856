const timeout = 500;

/**
 * Test csrf tokens exist on window, returns true if they exist
 */
function attemptWindowCsrf() {
  const test =
    window.csrfTokenName !== undefined && window.csrfTokenValue !== undefined;
  if (!test) {
    throw test;
  } else {
    return test;
  }
}

/**
 * Test Formie forms exist on window, returns true if they exist & have been mounted
 */
function attemptWindowFormie() {
  // Test csrf tokens exist on window, returns true if they exist
  const test =
    window.Formie !== undefined && window.Formie.$forms !== undefined;
  if (!test) {
    throw test;
  } else {
    return test;
  }
}

/**
 * @param reason
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rejectDelay(reason: any) {
  return new Promise(function (resolve, reject) {
    setTimeout(reject.bind(null, reason), timeout);
  });
}

type attemptType = () => void;

/**
 * Adapted from: https://stackoverflow.com/a/38225011/1463467
 * @param attempt function
 */
export function retry(attempt: attemptType) {
  const max = 50;
  let p = Promise.reject();

  for (let i = 0; i < max; i++) {
    p = p.catch(attempt).catch(rejectDelay);
    // Don't be tempted to simplify this to `p.catch(attempt).then(test, rejectDelay)`. Test failures would not be caught.
  }
  return p.catch((err) => console.error(err));
}

/**
 * Used by Vuex actions to attach the latest CSRF to their payloads
 * @param payload
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function attachCsrfToPayload(payload: any = {}) {
  await retry(attemptWindowCsrf);
  const { csrfTokenName, csrfTokenValue } = window;
  payload[csrfTokenName] = csrfTokenValue;
  return payload;
}

type SetWindowPropertyValues = {
  csrfTokenName: string;
  csrfTokenValue: string;
};
/**
 * Assigns CSRF name and token value to window, only used by attachCsrfToPayload at time of writing
 * @param csrfTokenName
 * @param csrfTokenValue
 */
const setWindowPropertyValues = ({
  csrfTokenName,
  csrfTokenValue,
}: SetWindowPropertyValues) => {
  window.csrfTokenName = csrfTokenName;
  window.csrfTokenValue = csrfTokenValue;
};

type SetFormInputCsrfValue = {
  form: HTMLFormElement;
  csrfTokenName: string;
  csrfTokenValue: string;
};
/**
 * Set a new csrf input value on a passed form element
 * @param form
 * @param csrfTokenName
 * @param csrfTokenValue
 */
const setFormInputCsrfValue = ({
  form,
  csrfTokenName,
  csrfTokenValue,
}: SetFormInputCsrfValue) => {
  const csrfInputEl = <HTMLInputElement>(
    form.querySelector(`input[name="${csrfTokenName}"]`)
  );
  csrfInputEl.value = csrfTokenValue;
};

type SetSubmitButtonDisabled = {
  submitButtonEl: HTMLButtonElement;
  disabled: boolean;
};

/**
 * Set a submit button to be disabled where CSRF is not yet available, or remove the attribute completely otherwise (re-enabling it)
 * @param submitButtonEl
 * @param disabled
 */
const setSubmitButtonDisabled = ({
  submitButtonEl,
  disabled,
}: SetSubmitButtonDisabled) => {
  if (disabled) {
    submitButtonEl.setAttribute("disabled", true);
    return;
  }
  submitButtonEl.removeAttribute("disabled");
};

const getCsrf = async () => {
  // Gets the CSRF Name
  const csrfTokenName = await fetch("/actions/blitz/csrf/param").then(
    (result) => {
      return result.text();
    }
  );
  // Gets the CSRF token
  const csrfTokenValue = await fetch("/actions/blitz/csrf/token").then(
    (result) => {
      return result.text();
    }
  );
  return {
    csrfTokenName,
    csrfTokenValue,
  };
};

/**
 * Request a csrf value for a particular form
 * @param handle
 */
const getFormCsrf = async (handle: string) => {
  return await fetch(
    `/actions/formie/forms/refresh-tokens?form=${handle}`
  ).then((res) => res.json());
};

async function initiateCsrfRetrieval() {
  const csrf = await getCsrf();
  setWindowPropertyValues(csrf);
}

export async function initiateFormCsrfRetrieval() {
  // Check an element exists, so we know if we need to wait for the window.Formie.$forms to populate
  // Be aware we CANNOT use this element as it is not be the same DOM element which Vue will mount
  // see https://verbb.io/craft-plugins/formie/docs/developers/javascript-api#async-modules
  const formsExist = document.querySelector(".fui-form");
  if (!formsExist) return;
  // Wait for window.Formie.$forms to populate
  await retry(attemptWindowFormie);
  const forms = window.Formie.$forms;
  /**
   * If forms exist, make a request to formie to get token, param & captcha info
   *   It might be that this logic is entirely unnecessary if google recaptcha
   *   does not need uncached keys, though leaving the logic just in case.
   *   Might be able to revert for everything to use the keys above instead.
   */

  for (const form of forms) {
    const submitButtonEl = <HTMLButtonElement>(
      form.querySelector('button[type="submit"]')
    );
    setSubmitButtonDisabled(<SetSubmitButtonDisabled>{
      submitButtonEl,
      disabled: true,
    });
    const handleInputEl = <HTMLInputElement>(
      form.querySelector('input[name="handle"]')
    );
    if (!handleInputEl) return;
    const { value } = handleInputEl;
    const csrf = await getFormCsrf(value);
    const csrfTokenName = csrf.csrf.param;
    const csrfTokenValue = csrf.csrf.token;
    // update the window object property values
    setWindowPropertyValues(<SetWindowPropertyValues>{
      csrfTokenName,
      csrfTokenValue,
    });
    // update the form inputs
    setFormInputCsrfValue(<SetFormInputCsrfValue>{
      form,
      csrfTokenName,
      csrfTokenValue,
    });
    setSubmitButtonDisabled(<SetSubmitButtonDisabled>{
      submitButtonEl,
      disabled: false,
    });
  }
}

export default function init() {
  initiateCsrfRetrieval();
  initiateFormCsrfRetrieval();
}
