function setItem(key: string, value: any) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    if (e instanceof DOMException && e.name === 'QuotaExceededError') {
      console.error('Quota exceeded! Cannot save data to local storage, clearing local storage...');
      window.localStorage.clear();
      return;
    } else {
      console.error('An unexpected error occurred:', e);
      return;
    }
  }
}

// Check the local storage quota capacity and clear it if it exceeds the limit
export default function init(key: string) {
  if (localStorage.getItem(key) !== null) {
    let obj: any = localStorage.getItem(key);
    obj = JSON.parse(obj);

    // Ensure testKey is an array before pushing new items
    if (!Array.isArray(obj.testKey)) {
      obj.testKey = [];
    }

    // Add 1000 new items to testKey
    for (let i = 1; i <= 1000; i++) {
      const newKeyValueObject = {};
      newKeyValueObject[`key${i}`] = `value${i}`;
      obj.testKey.push(newKeyValueObject);
    }

    // Store the updated object in local storage
    setItem(key, obj);

    // Remove the test key value
    delete obj.testKey;

    // Store the final object in local storage again
    setItem(key, obj);
  }
}
