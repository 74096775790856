/* eslint-disable @typescript-eslint/no-explicit-any */
import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {watch} from 'vue';

import {
  CustomerOverview,
  OrderHistory,
  PreviousOrder,
  Product,
  State,
  Statuses,
  Variant,
} from '/src/vue/stores/customer.d.ts';

import { attachCsrfToPayload } from "/src/ts/commons/csrf";
import localStorageCapacityTest from '/src/ts/modules/localStorageCapacityTest';

export default function init() {
  const key = 'vuex-customer-2024-05-20';
  localStorageCapacityTest(key);

  const fetchAbort: any = null;
  // Create a store instance.
  return createStore<State>({
    plugins: [createPersistedState({
      key,
      paths: [
        'annualSpend',
        'orderHistory',
        'previousOrder',
        'customerOverview',
        'cartTotalQuantity',
        'sortOrder',
        'statuses',
        'variants',
        'products',
      ],
    })],
    state() {
      return {
        annualSpend: null,
        orderHistory: null,
        previousOrder: null,
        customerOverview: {
          name: null,
          email: null,
          accountId: null,
          shipping: null,
          billing: null,
        },
        statuses: {
          isCustomer: null,
          isCustomerWithSaleAccess: null,
          isStopped: null,
          isInMaintenanceMode: null,
        },
        variants: [],
        products: [],
        cartTotalQuantity: null,
        sortOrder: 'asc',
        getPricesProcessing: false,
        productImportDateTime: null,
        getProductImportDateTimeProcessing: false,
      };
    },
    getters: {
      annualSpend: (state) => state.annualSpend,
      statuses: (state) => state.statuses,
      products: (state) => state.products,
      orderHistory: (state) => state.orderHistory,
      cartTotalQuantity: (state) => state.cartTotalQuantity,
      productsFromCategory: (state) => (productCategoryId: string) => {
        return state.products.filter((product: any) => {
          if (!product.productCategories) {
            return;
          }
          let inCategory: any
          // As results could be for either category type, check either are populated first
          if (product.productCategories.length) {
            inCategory = product.productCategories.filter((cat: any) => cat.id === productCategoryId).length;
          } else if (product.sosProductCategories.length) {
            inCategory = product.sosProductCategories.filter((cat: any) => cat.id === productCategoryId).length;
          }
          return inCategory;
        });
      },
      privateDownloads: (state) => (defaultSku: string) => {
        const product = state.products.find((item: any) => item.defaultSku === defaultSku);
        return product ? product.downloads : null;
      },
      variants: (state) => state.variants,
      variantPrice: (state) => (sku: string) => {
        const variant = state.variants.find((item: any) => item.sku === sku);
        return variant ? variant.price : null;
      },
      isVariantPriceValid: (state) => (sku: string) => {
        // Where import data time is null, return because it'll compare against 1970 if we don't
        if (state.productImportDateTime === null) {
          return false;
        }
        const variant = state.variants.find((item: any) => item.sku === sku);
        // Check if storedAt DateTime is older than productImportDateTime DateTime
        return !(variant && variant.price && variant.storedAt && new Date(variant.storedAt) < new Date(state.productImportDateTime));
      },
      variantStoredAt: (state) => (sku: string) => {
        const variant = state.variants.find((item: any) => item.sku === sku);
        return variant ? variant.storedAt : null;
      },
      variantSurcharge: (state) => (sku: string) => {
        const variant = state.variants.find((item: any) => item.sku === sku);
        return variant ? variant.surcharge : null;
      },
      variantSurchargeRaw: (state) => (sku: string): number|null => {
        const variant = state.variants.find((item: any) => item.sku === sku);
        return variant ? variant.surchargeRaw : null;
      },
      variantBulkPrice: (state) => (sku: string) => {
        const variant = state.variants.find((item: any) => item.sku === sku);
        return variant ? variant.bulkPrice : null;
      },
      variantIsInStock: (state) => (sku: string) => {
        const variant = state.variants.find((item: any) => item.sku === sku);
        return variant ? variant.isInStock : null;
      },
      variantHasStockStatusFetchError: (state) => (sku: string) => {
        const variant = state.variants.find((item: any) => item.sku === sku);
        return variant ? variant.hasStockStatusFetchError : null;
      },
    },
    mutations: {
      setAnnualSpend(state, value: string) {
        state.annualSpend = value;
      },
      setOrderHistory(state, value: Array<OrderHistory>) {
        state.orderHistory = value;
      },
      setCartTotalQuantity(state, value: number) {
        state.cartTotalQuantity = value;
      },
      setPreviousOrder(state, previousOrder: PreviousOrder) {
        const {
          img,
          total,
          order,
          invoice,
        } = previousOrder;

        state.previousOrder = {
          img,
          total,
          order,
          invoice,
        };
      },
      /**
       * sets prices for variants
       * @param state
       * @param variants
       */
      setVariants(state, variants: Array<Variant>) {
        if (variants.length) {
          variants.forEach((variant: Variant) => {
            this.commit('setVariant', variant);
          });
        }
      },
      /**
       * sets prices for single variant
       * also handles setting bulk price quantity and price
       * @param state
       * @param variant
       */
      setVariant(state, variant: Variant) {
        if (!variant) {
          console.error('No variant provided');
          return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const existingVariant = state.variants.find((item: any) => item.sku === variant.sku);
        if (existingVariant) {
          state.variants = [...state.variants.map((item: Variant) => item.sku !== variant.sku ? item : { ...item, ...variant })];
          return true;
        }
        state.variants.push(variant);
      },
      /**
       * @param state
       * @param product
       */
      setProduct(state, product: Product) {
        if (!product) {
          console.error('No product provided');
          return false;
        }
        // Save promotable status of a product card
        const hasVariantSales = product.variants.length ? product.variants.find((item: any) => {
          if (item.sales === null) {
            return;
          }
          return item.sales.length;
        }) : 0;
        product.isOnSale = hasVariantSales ? hasVariantSales.sales.length > 0 : false;
        // If product exists in storage already, updated it
        const existingProduct = state.products.find((item: any) => item.defaultSku === product.defaultSku);
        if (existingProduct) {
          state.products = [...state.products.map((item: any) => (item.defaultSku !== product.defaultSku ? item : { ...item, ...product }))];
          return true;
        }
        // If product doesn't exist, push it to state
        state.products.push(product);
        return true;
      },
      setStatuses(state, statuses: Statuses) {
        state.statuses = statuses;
      },
      setCustomerOverview(state, customerOverview: CustomerOverview) {
        const {
          name,
          email,
          accountId,
          shipping,
          billing,
        } = customerOverview;

        state.customerOverview = {
          name,
          email,
          accountId,
          shipping,
          billing,
        };
      },
    },
    actions: {
      /**
       * Used by actions which will be making requests to private endpoints to ensure that the
       * current user has suitable permission to do so.
       * This action solves an issue where the statuses of the current user are often not hydrated
       * in the store at the point the checks are made, so this action will:
       * - check if the statuses are hydrated
       *     - checks user has permission
       * - if not, watches for them to be hydrated
       *     - when hydrated checks user has permission
       *     - runs the original action
       *
       * @param { state } the current store's state
       * @param action the action that will be called again once statuses have been hydrated
       * @param payload the original payload passed to the original action
       * @returns
       */
      checkUserIsCustomerAndNotStopped({state}, {action, payload}) {
        // Check to see if state variables have been hydrated
        if (state.statuses.isCustomer === null || state.statuses.isStopped === null) {
          /**
           * Watch state customer & stop variable which may have never been
           * set before this setup function is returned to run the same
           */
          watch(
              () => (state.statuses.isCustomer && state.statuses.isStopped),
              () => {
                /**
                 * If the customer exist and doesn't have stop status they will
                 * succeed next time around
                 */
                if (state.statuses.isCustomer && !state.statuses.isStopped) {
                  // Trigger vuex action to retrieve private downloads for product
                  this.dispatch(action, payload);
                }
                /**
                 * As variants and products are now stored in local storage,
                 * we need to clear them if we find a user has a stopped status.
                 */
                else if (state.statuses.isCustomer && state.statuses.isStopped) {
                  state.variants = [];
                  state.products = [];
                  return false;
                }
              },
          );
          return false;
        }

        /**
         * If user is NOT a customer OR if user IS a customer AND customer's status is STOP
         * thou shall not pass
         * As variants and products are now stored in local storage,
         * we need to clear them if we find a user has a stopped status.
         */
        if (!state.statuses.isCustomer || state.statuses.isStopped) {
          state.variants = [];
          state.products = [];
          return false;
        }
        return true;

      },
      async getCustomerOverview({commit}) {
        try {
          const payload = await attachCsrfToPayload({});
          const res = await fetch('/actions/commerce-api/customer/get-customer-overview', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          commit('setCustomerOverview', json);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      async getAnnualSpend({commit}) {
        try {
          const payload = await attachCsrfToPayload({});
          const res = await fetch('/actions/commerce-api/customer/get-annual-spend', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          commit('setAnnualSpend', json.annualSpend);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      async getOrderHistory({commit}) {
        try {
          const payload = await attachCsrfToPayload({});
          const res = await fetch('/actions/commerce-api/customer/get-order-history', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          commit('setOrderHistory', json);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      async getCartTotalQuantity({commit}) {
        try {
          const payload = await attachCsrfToPayload({});
          const res = await fetch('/actions/commerce-api/customer/get-cart-total-quantity', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          commit('setCartTotalQuantity', json.quantity);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      async getStatuses({commit}) {
        try {
          const payload = await attachCsrfToPayload({});
          const res = await fetch('/actions/commerce-api/customer/get-statuses', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          commit('setStatuses', json);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      async getVariantStockStatus({commit}, payload) {
        try {
          payload = await attachCsrfToPayload(payload);
          const res = await fetch('/actions/commerce-api/customer/get-variant-stock-status', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          if (!res.ok) {
            commit('setVariant', {
              sku: payload.sku,
              hasStockStatusFetchError: true,
              isInStock: false,
            });
            console.error(res.status);
          }
          commit('setVariant', {
            ...await res.json(),
            hasStockStatusFetchError: false,
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          commit('setVariant', {
            sku: payload.sku,
            hasStockStatusFetchError: true,
            isInStock: false,
          });
          return false;
        }
        return true;
      },
      async getProductImportDateTime({ commit, state }, payload: any) {
        // Prevent multiple requests which could be caused by ProductPrice component rendered twice on 1 page (product)
        if (state.getProductImportDateTimeProcessing) return;
        state.getProductImportDateTimeProcessing = true;
        // Get the last product import date time
        payload = Object.assign({}, payload);
        payload = await attachCsrfToPayload(payload);
        try {
          const res = await fetch('/actions/commerce-api/customer/get-product-import-date-time', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          state.productImportDateTime = json.dateTime;
          state.getProductImportDateTimeProcessing = false;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          state.getProductImportDateTimeProcessing = false;
          return null;
        }
      },
      async getPrices({commit, state}, payload: any) {
        // Prevent multiple requests which could be caused by ProductPrice component rendered twice on 1 page (product)
        if (state.getPricesProcessing) return;
        state.getPricesProcessing = true;
        payload = Object.assign({}, payload);
        payload = await attachCsrfToPayload(payload);
        const isCustomerAndNotStopped = await this.dispatch('checkUserIsCustomerAndNotStopped', {
          action: 'getPrices',
          payload,
        });
        if (!isCustomerAndNotStopped) {
          state.getPricesProcessing = false;
          return;
        }
        /**
         * Check all variants in state to see if their stored at value is older than the latest product import date time value.
         * Where it is, we need to remove the variant from state so that we can fetch the latest price.
         */
        payload.productSkus.forEach((sku: string) => {
          const variant = state.variants.find((item: Variant) => item.sku === sku);
          if (variant && variant.storedAt && new Date(variant.storedAt) < new Date(state.productImportDateTime)) {
            state.variants = state.variants.filter((item: Variant) => item.sku !== sku);
          }
        });
        /**
         * Manipulate payload as we do not need to retrieve prices
         * for products which we already have
         */
        const skusToRequest: Array<string> = [];
        payload.productSkus.forEach((sku: string) => {
          const variant = state.variants.find((item: Variant) => item.sku === sku);
          // variant not found with this payload sku, or, if a variant is found with no price, we need to fetch it!
          if (!variant || variant && variant.price === undefined || variant && variant.surcharge === undefined) {
            skusToRequest.push(sku);
          }
        });
        if (!skusToRequest.length) {
          state.getPricesProcessing = false
          return;
        }
        /**
         * As payload could be a frozen object (non-reactive), we create a new copy of it
         *   so that we can manipulate the productSkus property
         */
        payload = {
          ...payload,
          productSkus: skusToRequest,
        };
        try {
          const res = await fetch('/actions/commerce-api/customer/get-prices', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          // set date time in ios8601 format on each variant with map()
          const storedAt = new Date();
          json.forEach((variant: Variant) => variant.storedAt = storedAt.toISOString());
          // Set the variants
          commit('setVariants', json);
          state.getPricesProcessing = false
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          state.getPricesProcessing = false
          return false;
        }
        return true;
      },
      async getBulkPrice({state, commit}, payload: any) {
        payload = await attachCsrfToPayload(payload);
        const isCustomerAndNotStopped = await this.dispatch('checkUserIsCustomerAndNotStopped', {
          action: 'getBulkPrice',
          payload,
        });
        if (!isCustomerAndNotStopped) {
          return;
        }
        // Check if bulk price for sku exists to prevent unnecessary requests
        const variant = state.variants.find((item: Variant) => item.sku === payload.sku);
        if (variant && Object.prototype.hasOwnProperty.call(variant, "bulkPrice") && variant.bulkPrice) {
          return;
        }
        try {
          const res = await fetch('/actions/commerce-api/customer/get-bulk-price', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const json = await res.json();
          commit('setVariant', json);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          return false;
        }
        return true;
      },
      async getPrivateDownloads({commit}, defaultSku: string) {
        const isCustomerAndNotStopped = await this.dispatch('checkUserIsCustomerAndNotStopped', {
          action: 'getPrivateDownloads',
          payload: defaultSku,
        });
        if (!isCustomerAndNotStopped) {
          return;
        }
        // Prep the payload
        let payload = {
          defaultSku,
        };
        payload = await attachCsrfToPayload(payload);

        try {
          const res = await fetch('/actions/commerce-api/customer/get-private-downloads', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(payload),
          });
          const downloads = await res.json();
          if (!downloads) {
            return;
          }
          const product = {
            defaultSku,
            downloads,
          };
          commit('setProduct', product);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
    },
  });
}
