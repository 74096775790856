import {
  defineComponent, computed,
} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  delimiters: ['${', '}'],
  setup() {
    const store = useStore();
    const isCustomer = computed(() => store.getters.statuses.isCustomer);
    return {
      isCustomer,
    };
  },
  template: '#t-utility-nav-link-offers',
});
