import { createApp } from 'vue';
import InstantSearch from 'vue-instantsearch/vue3/es';

// Components
import CartTotalQuantity from '/src/vue/components/CartTotalQuantity.ts';
import UtilityNavLinkOffers from '/src/vue/components/UtilityNavLinkOffers.ts';
import Search from '/src/vue/components/Search.ts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function init(store: any) {
  // Create our vue instance
  const vueInstance = createApp({
    delimiters: ['${', '}'],
    components: {
      CartTotalQuantity,
      UtilityNavLinkOffers,
      Search,
    },
    setup() {
      /*
       * Where user is on the store checkout, we don't need to get
       * statuses as a customer's stopped status is checked at template level
       */
      const checkoutUrlPattern = /^\/store\/checkout/;
      if (checkoutUrlPattern.test(window.location.pathname)) {
        return;
      }
      /**
       * getStatuses is used by multiple components & vue instances
       * set it in this header vue instance so state is updated everywhere
       */
      store.dispatch('getStatuses');
    },
    data() {
      return {
        isMobileOpen: false,
        childOption: '',
      };
    },
    computed: {
      isCustomer() {
        return store.getters.statuses.isCustomer;
      }
    },
    methods: {
      openMobileMenu() {
        this.isMobileOpen = true;
        document.body.classList.toggle('overflow-hidden');
      },
      closeMobileMenu() {
        this.isMobileOpen = false;
        document.body.classList.toggle('overflow-hidden');
      },
      toggleChild(childId: string) {
        if (!childId) return;
        const el = document.getElementById(childId);
        if (!el) return;
        el.classList.toggle('-translate-x-full');
      },
    },
  });
  // Use the store
  vueInstance.use(store);
  // Use InstantSearch
  vueInstance.use(InstantSearch);
  // Mount the app
  vueInstance.mount('#header');
}
