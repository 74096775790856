import {
  defineComponent
} from 'vue';

export default defineComponent({
  delimiters: ['${', '}'],
  computed: {
    isCustomer() {
      return this.$store.getters.statuses.isCustomer;
    },
    isStopped() {
      return this.$store.getters.statuses.isStopped;
    },
    quantity() {
      return this.$store.getters.cartTotalQuantity;
    }
  },
  watch: {
    isCustomer() {
      this.getBasketQuantity();
    },
    isStopped() {
      this.getBasketQuantity();
    },
  },
  mounted() {
    this.getBasketQuantity();
  },
  methods: {
    hasPricingAccess() {
      return this.isCustomer && !this.isStopped;
    },
    getBasketQuantity() {
      if (!this.hasPricingAccess()) {
        return;
      }
      this.$store.dispatch('getCartTotalQuantity');
    },
  },
  template: '#t-cart-total-quantity',
});
