import {createApp, defineAsyncComponent} from 'vue';

export default function init() {
  // Create our vue instance
  const vueInstance = createApp({
    components: {
      FooterToTop: defineAsyncComponent(() => import('/src/vue/components/FooterToTop.ts')),
    },
  });
  // Mount the app
  return vueInstance.mount('#footer');
}
